<template>
  <div
    class="table__container"
  >
    <div class="transaction__filter--container bg-white py-2">
      <div class="d-flex align-items-center search__export w-100">
        <div class="d-flex pl-2">
          <b-img
            class="filter-image"
            :class="filter.sort_type == 'asc' ? 'rotate-180' : ''"
            :src="require('@/assets/images/icons/Icon-order-list-down.svg')"
            alt="Icon-order-list-down"
            role="button"
            @click="filter.sort_type == 'asc' ? filter.sort_type = 'desc' : filter.sort_type = 'asc'"
          />
        </div>
        <div class="search__input w-100 px-2">
          <b-input-group>
            <b-form-input
              v-model="filter.search"
              placeholder="Cari nama penerima atau No. Invoice/SPT"
            />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
    </div>

    <vue-good-table
      max-height="80vh"
      :columns="columns"
      :rows="items"
      :fixed-header="false"
      :sort-options="{
        enabled: false
      }"
      :pagination-options="{
        enabled: false,
        perPage:pageLength
      }"
    >
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span v-if="props.column.field == 'action'" />

        <span v-else-if="props.column.field == 'cetak'">
          <span v-if="checkPermission('detail pembayaran penjualan')">
            Cetak
          </span>
        </span>
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: TOtal -->
        <span v-if="props.column.field === 'total'">
          <span>{{ props.row.total | formatAmount }}</span>
        </span>
        <!-- Column: Penerima -->
        <span v-else-if="props.column.field === 'customer.name'">
          <span>{{ props.row.customer && props.row.customer.name || '-' }}</span>
        </span>
        <!-- Column: Payment -->
        <span v-else-if="props.column.field === 'payment_method'">
          <span class="text-capitalize">{{ props.row.payments.map(e => getLabel(e.payment_method)).join(', ') }}</span>
        </span>
        <!-- Column: Tipe -->
        <span v-else-if="props.column.field === 'customer.customer_type'">
          <span>{{ props.row.customer && props.row.customer.customer_type || '-' }}</span>
        </span>
        <!-- Column: Cetak -->
        <span v-else-if="props.column.field === 'cetak'">
          <div>
            <b-button
              class="bg-white border-8 border-0 px-5 py-1"
              @click="goToCetak(props.row.uuid)"
              v-if="checkPermission('detail pembayaran penjualan')"
            >
              Cetak
            </b-button>
          </div>
        </span>

        <!-- Column: Action -->
        <!-- <span v-else-if="props.column.field === 'action'">
          <b-dropdown
            id="dropdown-dropleft"
            class="d-flex"
            right
          >
            <template
              #button-content
              class="btn-white text-center"
            >
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle"
              />
            </template>
            <b-dropdown-item @click="cancelPayment(props.row.uuid)">
              <span>
                Cancel Pembayaran
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </span> -->

        <!-- Column: Common -->
        <span
          v-else
          class="text-capitalize"
        >
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>

    <div
      v-if="rows && isLoading == false"
      class="d-flex justify-content-between align-items-center flex-wrap bg-white border"
      style="padding: 0.8rem;"
    >
      <div class="d-flex align-items-center">
        <b-form-select
          v-model="filter.per_page"
          :options="['10','15','20']"
          class="mx-1"
        />
        <span class="text-nowrap">
          Tampilkan {{ (rows.total > 0) ? (filter.per_page * (rows.current_page - 1)) + 1 : 0 }} ke
          {{ (rows.total > 0) ? Math.min(filter.per_page * rows.current_page, rows.total) : 0 }} dari {{ rows.total }} transaksi.
        </span>
      </div>
      <div>
        <b-row class="mx-0 align-items-center">
          <b-col>
            <pagination
              class="mb-0"
              :data="rows"
              :limit="4"
              align="right"
              @pagination-change-page="getData"
            />
          </b-col>
        </b-row>
      </div>
    </div>

  </div>
</template>

<script>
import {
  BFormSelect, VBToggle, BFormInput, BInputGroup, BInputGroupAppend, BImg, BButton, BRow, BCol, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification,
    }
  },
  data() {
    return {
      pageLength: 10,
      columns: [
        {
          label: 'Tanggal',
          field: 'date',
        },
        {
          label: 'No. Invoice/SPT',
          field: 'invoice_number',
        },
        {
          label: 'Penerima',
          field: 'customer.name',
        },
        {
          label: 'Tipe',
          field: 'customer.customer_type',
        },
        {
          label: 'Nilai',
          field: 'total',
        },
        {
          label: 'Metode Pembayaran',
          field: 'payment_method',
        },
        {
          label: 'Cetak',
          field: 'cetak',
        },
      ],
      rows: {},
      items: [],
      filter: {
        search: '',
        per_page: 10,
        sort_type: 'desc',
      },
      page: 1,
    }
  },
  watch: {
    filter: {
      handler() {
        this.getData()
      },
      deep: true,
    },
  },
  created() {
    if (checkPermission('list pembayaran penjualan')) {
      this.getData()
    }
  },
  methods: {
    getLabel(payment) {
      const payments = {
        tunai: 'Tunai',
        transfer: 'Transfer Bank',
        card: 'Kredit atau Debit',
        giro: 'Giro',
        wallet: 'Deposit',
        piutang: 'Piutang',
        cash: 'Tunai',
      }
      return payments[payment] || '-'
    },
    goToCetak(uuid) {
      this.$router.push({ name: 'invoice.index', params: { id: uuid }, query: { q: 'payment_report', type: 'order', pembayaran: 'penjualan', page: this.page } })
    },
    cancelPayment(id) {
      this.$store.dispatch('payout/cancelOthers', {
        uuid: `${id}/cancel`,
        params: '',
      }).then(result => {
        // this.rows = result.data.data
        // this.isLoading = false
        // console.log(result);
      }).catch(err => {
        console.log(err)
      })
    },
    getData(page = this.$route.query.page || 1) {
      this.isLoading = true
      const queryParams = this.filter
      queryParams.page = page
      this.page = page

      this.$store.dispatch('order/getData', {
        uuid: '',
        params: queryParams,
      }).then(result => {
        this.rows = result.data.data
        this.items = result.data.data.data
        this.isLoading = false
        // console.log(result);
      }).catch(err => {
        if (err.response.data.meta.messages) {
          errorNotification(this, 'Oops!', err.response.data.meta.messages)
        }
        this.isLoading = false
        console.log(err)
      })
    },
  },

}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

.vgt-responsive {
  height: calc(100vh - 235px) !important;
  background-color: #ffffff;
}
.vgt-table.bordered {
  th {
    vertical-align: middle;
  }
}
</style>
